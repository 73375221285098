var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth",
    [
      [
        _c(
          "a-row",
          {
            staticClass: "ant-row-flex",
            staticStyle: { height: "100vh" },
            attrs: { align: "middle", justify: "space-around", type: "flex" }
          },
          [
            _c(
              "a-col",
              { attrs: { lg: 14, md: 16, sm: 18, xl: 10, xs: 20, xxl: 8 } },
              [
                _c(
                  "a-row",
                  {
                    staticClass: "ant-row-flex",
                    staticStyle: {
                      "background-color": "rgba(255, 255, 255, 0.75)"
                    },
                    attrs: {
                      align: "middle",
                      justify: "space-around",
                      type: "flex"
                    }
                  },
                  [
                    _c("a-col", { attrs: { md: 8, sm: 18, xs: 18 } }, [
                      _vm.orgLogoUrl
                        ? _c("img", {
                            staticClass: "center",
                            attrs: { src: _vm.orgLogoUrl }
                          })
                        : _vm._e()
                    ]),
                    _c(
                      "a-col",
                      { attrs: { md: 16, sm: 18, xs: 18 } },
                      [
                        _vm.errors
                          ? _c("a-alert", {
                              staticStyle: { "margin-bottom": "30px" },
                              attrs: {
                                banner: "",
                                message: "Nom ou mot de passe erroné",
                                type: "error"
                              }
                            })
                          : _vm._e(),
                        _c(
                          "a-form",
                          {
                            staticStyle: { padding: "5%" },
                            attrs: { form: _vm.form, layout: "vertical" },
                            on: { submit: _vm.handleSubmit }
                          },
                          [
                            _c(
                              "h1",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("Connexion")]
                            ),
                            _c(
                              "div",
                              { staticClass: "splitter" },
                              [
                                _c(
                                  "a-form-item",
                                  _vm._b(
                                    {},
                                    "a-form-item",
                                    _vm.formItemLayout,
                                    false
                                  ),
                                  [
                                    _c(
                                      "a-input",
                                      {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "email",
                                              {
                                                rules: [
                                                  {
                                                    required: true,
                                                    message:
                                                      "Email est obligatoire!"
                                                  }
                                                ]
                                              }
                                            ],
                                            expression:
                                              "[\n                    'email',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Email est obligatoire!',\n                        },\n                      ],\n                    },\n                  ]"
                                          }
                                        ],
                                        attrs: {
                                          autocomplete: "email",
                                          placeholder: "Email",
                                          type: "email"
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            color: "rgba(0,0,0,.25)"
                                          },
                                          attrs: {
                                            slot: "prefix",
                                            type: "mail"
                                          },
                                          slot: "prefix"
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-item",
                                  _vm._b(
                                    {},
                                    "a-form-item",
                                    _vm.formItemLayout,
                                    false
                                  ),
                                  [
                                    _c(
                                      "a-input-password",
                                      {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "password",
                                              {
                                                rules: [
                                                  {
                                                    required: true,
                                                    message:
                                                      "Mot de passe est obligatoire!"
                                                  }
                                                ]
                                              }
                                            ],
                                            expression:
                                              "[\n                    'password',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Mot de passe est obligatoire!',\n                        },\n                      ],\n                    },\n                  ]"
                                          }
                                        ],
                                        attrs: {
                                          autocomplete: "current-password",
                                          placeholder: "Mot de passe"
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            color: "rgba(0,0,0,.25)"
                                          },
                                          attrs: {
                                            slot: "prefix",
                                            type: "lock"
                                          },
                                          slot: "prefix"
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-item",
                                  _vm._b(
                                    { staticClass: "margin" },
                                    "a-form-item",
                                    _vm.tailFormItemLayout,
                                    false
                                  ),
                                  [
                                    _c(
                                      "a-spin",
                                      { attrs: { spinning: _vm.spinning } },
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            staticClass: "regiter-form-button",
                                            attrs: {
                                              id: "login",
                                              htmlType: "submit",
                                              type: "primary"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.spinning
                                                  ? "Connexion..."
                                                  : "Connexion"
                                              ) + "\n                          "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }