<template>
    <auth>
    <template slotscope="defaultSlotScope">
    <a-row
      align="middle"
      class="ant-row-flex"
      justify="space-around"
      style="height :100vh ;   "
      type="flex"
    >
      <a-col :lg="14" :md="16" :sm="18" :xl="10" :xs="20" :xxl="8">
        <a-row
          align="middle"
          class="ant-row-flex"
          justify="space-around"
          style="background-color: rgba(255, 255, 255, 0.75);   "
          type="flex"
        >
          <a-col :md="8" :sm="18" :xs="18">
            <img :src="orgLogoUrl" class="center" v-if="orgLogoUrl" />
          </a-col>

          <a-col :md="16" :sm="18" :xs="18">
              <a-alert
                  banner
                  message="Nom ou mot de passe erroné"
                  style="margin-bottom: 30px;"
                  type="error"
                  v-if="errors"
              />
              <a-form
                  @submit="handleSubmit"
                  :form="form"
                  layout="vertical"
                  style="padding: 5%;"
              >
                  <h1 style="text-align : center">Connexion</h1>
                  <div class="splitter">
                      <a-form-item v-bind="formItemLayout">
                          <a-input
                              v-decorator="[
                        'email',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Email est obligatoire!',
                            },
                          ],
                        },
                      ]"
                              autocomplete="email"
                              placeholder="Email"
                              type="email"
                          >
                              <a-icon
                                  slot="prefix"
                                  style="color: rgba(0,0,0,.25)"
                                  type="mail"
                              />
                          </a-input>
                      </a-form-item>
                      <a-form-item v-bind="formItemLayout">
                          <a-input-password
                              v-decorator="[
                        'password',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Mot de passe est obligatoire!',
                            },
                          ],
                        },
                      ]"
                              autocomplete="current-password"
                              placeholder="Mot de passe"
                          >
                              <a-icon
                                  slot="prefix"
                                  style="color: rgba(0,0,0,.25)"
                                  type="lock"
                              />
                          </a-input-password>
                      </a-form-item>

                      <a-form-item class="margin" v-bind="tailFormItemLayout">
                          <a-spin :spinning="spinning">
                              <a-button
                                  id="login"
                                  class="regiter-form-button"
                                  htmlType="submit"
                                  type="primary"
                              >{{ spinning ? "Connexion..." : "Connexion" }}
                              </a-button
                              >
                          </a-spin>
                      </a-form-item>
                  </div>
              </a-form>

          </a-col>
        </a-row>
      </a-col>
    </a-row>
    </template>
    </auth>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Auth from "@/components/common/AuthParent";

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};
const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
export default {
  name: "AdminLogin",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created() {
    this.clearAllTimeouts();
    this.resetAll();
    this.getOrganisationAssets()
     .then(() => this.spinning = false);
    this.returnUrl = this.$route.query.returnUrl || "/admin/adherents";
  },
  components: {
    Auth
  },
  computed: {
    ...mapGetters({
      orgLogoUrl: "getOrganisationLogo",
      orgBgUrl: "getOrganisationBgImg",
    }),
  },
  data() {
    return {
      confirmDirty: false,
      returnUrl: "",
      errors: false,
      loginTimeout: null,
      spinning : false,
      formItemLayout,
      tailFormItemLayout,
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      // let _this = this;
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          let user = {
            email: values.email,
            password: values.password,
          };
          this.auth({ user, role: "admin" })
            .then(() => this.$router.push(this.returnUrl))
            .catch(() => this.displayErrors())
            .finally(() => this.spinning = false)
        }
      });
    },
    displayErrors() {
       this.$message.error('Vérifier votre mot de passe et/ou email', 4)
    },
    ...mapActions({
      auth: "authenticate",
      getReceivedMessages: "fetchReceivedMessages",
      getOrganisationAssets: "fetchOrganisationLogoAndLoginBackgroundImage",
    }),
    ...mapMutations({
      logout: "clearAuthentication",
      resetAll: "clearAuthentication",
    }),
  },
  destroyed() {
    clearInterval(this.loginTimeout);
  },
};
</script>

<style scoped>
.splitter {
  border-left: solid;
  border-left-style: solid;
  border-width: thin;
  border-left-color: black;
  padding-left: 10px;
}

.ant-row-flex:before {
  z-index: 1;
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;
  display: block;
}

.regiter-form-button {
  width: 100%;
}
.bg_img {
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}
.c-footer {
  background: #009688;
}
</style>
